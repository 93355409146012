<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  version?: 'AR' | 'EN',
  variant: 'icon' | 'full'
}>(), {
  version: 'EN',
  variant: 'full',
});

const logoSrc = computed(() => {

  if(props.variant === 'icon') 
    return 'https://res.cloudinary.com/mrdestiny/image/upload/v1723572185/osos_uhyuhf.png';

  if(props.version === 'AR')
    return 'https://res.cloudinary.com/mrdestiny/image/upload/v1723572184/ososarabc_idjqa2.png';

  return 'https://res.cloudinary.com/mrdestiny/image/upload/v1723572184/ososlogfull_djxg2f.png';
});
</script>
<template>
  <img
    :src="logoSrc"
    class=""
  >
</template>
